<style lang="less">
// .news-center-page {}
</style>
<template>
  <div class="news-center-page">
    <div class="page-title">中心列表</div>
    <QueryOperator>
      <template slot="left">
        <a-button @click="addNews" icon="plus" type="primary">新建</a-button>
      </template>
      <template slot="right">
        <a-input allowClear class="query-action" v-model="queryParam.keyword" placeholder="标题或关键字"></a-input>
        <a-select @change="searchData" allowClear class="query-action" v-model="queryParam.type" placeholder="选择所属类别">
          <a-select-option v-for="item in newTypeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <a-select @change="searchData" allowClear class="query-action" v-model="queryParam.state" placeholder="选择所属状态">
          <a-select-option v-for="item in newsStatusList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <a-range-picker style="width: 225px;" v-model="rangeData" @change="searchData" class="query-action" />
        <a-button type="primary" @click="searchData">查询</a-button>
      </template>
    </QueryOperator>
    <a-card>
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataSource"
        :pagination="ipagination"
        @change="handleTableChange">
        <span slot="type" slot-scope="text, record">
          {{ newTypeHash[text] }}
        </span>
        <span slot="state" slot-scope="text, record">
          {{ newsStatusHash[text] }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="editNews(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗？" @confirm="() => delNews(record)">
            <a class="error">删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="setNewsTop(record)">{{ record.weight ? '取消置顶' : '置顶' }}</a>
        </span>
      </a-table>
    </a-card>
    <NewsEdit :fullscreen="true" @save="getNewsList" ref="newsEdit" />
  </div>
</template>

<script>
import moment from 'moment'
import QueryOperator from '@/components/Operator/QueryOperator'
import NewsEdit from './components/NewsEdit'
import { 
  getNewsList,
  getNewsTypeList,
  getNewsStatusList,
  setNewsTop,
  delNews
} from '@/api'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '浏览量',
    dataIndex: 'pv'
  },
  {
    title: '类别',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '发布时间',
    dataIndex: 'releaseDate'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]
export default {
  components: {
    QueryOperator,
    NewsEdit
  },
  data () {
    return {
      loading: false,
      // 查询参数
      queryParam: {
        // 标题
        keyword: undefined,
        // 类别
        type: 'industry',
        // 开始时间
        start: '',
        // 结束时间
        end: '',
        state: undefined, // 状态
      },
      // 默认时间
      // rangeData: [moment(), moment()],
      rangeData: [],
      columns,
      dataSource: [],
      // 分页
      ipagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      // 新闻类型列表
      newTypeList: [],
      newTypeHash: {},
      // 新闻状态列表
      newsStatusList: [],
      newsStatusHash: {}
    }
  },
  created () {
    // 新闻类型列表
    this.getNewsTypeList()
    // 新闻状态列表
    this.getNewsStatusList()
    // 获取新闻列表
    this.getNewsList()
  },
  methods: {
    // 新闻类型列表
    getNewsTypeList () {
      getNewsTypeList().then(data => {
        this.newTypeHash = {}
        if (data.code === 200) {
          this.newTypeList = data.data
          data.data.forEach(item => {
            this.newTypeHash[item.id] = item.name
          })
        }
      })
    },
    // 新闻状态列表
    getNewsStatusList () {
      getNewsStatusList().then(data => {
        this.newsStatusHash = {}
        if (data.code === 200) {
          this.newsStatusList = data.data
          data.data.forEach(item => {
            this.newsStatusHash[item.id] = item.name
          })
        }
      })
    },
    // 获取类型显示
    typeFilter (record) {
      const type = this.newTypeList.filter(item => item.id === record.type)
      if (type.length > 0) return type[0].name
      return ''
    },
    // 获取状态显示
    stateFilter (record) {
      const state = this.newsStatusList.filter(item => item.id === record.state)
      if (state.length > 0) return state[0].name
      return ''
    },
    // 获取新闻列表
    getNewsList () {
      if (this.rangeData.length > 0) {
        this.queryParam.start = moment(this.rangeData[0]).format('YYYY-MM-DD')
        this.queryParam.end = moment(this.rangeData[1]).format('YYYY-MM-DD')
      } else {
        this.queryParam.start = ''
        this.queryParam.end = ''
      }
      let params = {
        page: this.ipagination.current,
        size: this.ipagination.pageSize
      }
      params = { ...params, ...this.queryParam }
      // 清除空字段
      for (const k in params) {
        if (!params[k] && String(params[k]) !== '0') {
          delete params[k]
        }
      }
      this.loading = true
      getNewsList(params).then(data => {
        if (data.code === 200) {
          data.data.list.forEach(item => {
            item.key = item.id
          })
          this.dataSource = data.data.list.map(item => {
            return item
          })
          this.ipagination.total = data.data.total
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 分页选择
    handleTableChange (pagination) {
      this.ipagination = { ...pagination }
      // 获取新闻列表
      this.getNewsList()
    },
    // 新闻置顶
    setNewsTop (item) {
      const params = {
        // 0 取消置顶 1 置顶
        action: item.weight ? 0 : 1,
        id: item.id
      }
      setNewsTop(params).then(data => {
        if (data.code === 200) {
          // 获取新闻列表
          this.getNewsList()
          this.$message.success('操作成功！')
        } else {
          this.$message.error(data.message || '操作失败！')
        }
      })
    },
    // 新闻删除
    delNews (item) {
      delNews(item).then(data => {
        if (data.code === 200) {
          // 获取新闻列表
          this.getNewsList()
          this.$message.success('操作成功！')
        } else {
          this.$message.error(data.message || '操作失败！')
        }
      })
    },
    // 查询数据
    searchData () {
      this.ipagination.current = 1
      // 获取新闻列表
      this.getNewsList()
    },
    // 时间变化
    changeDate (date) {
      this.rangeData = date
    },
    editNews (record) { // 编辑新闻
      const title = this.newTypeHash[this.queryParam.type]
      this.$refs.newsEdit.handleEdit(record, '编辑' + title)
      this.$refs.newsEdit.newTypeList = this.newTypeList
    },
    addNews () { // 添加新闻
      const title = this.newTypeHash[this.queryParam.type]
      this.$refs.newsEdit.handleAdd({}, '添加' + title)
      this.$refs.newsEdit.newTypeList = this.newTypeList
    }
  }
}
</script>
