<template>
  <a-drawer
    :title="title"
    wrapClassName="form-edit-drawer"
    placement="right"
    :visible="visible"
    :width="drawerWidth"
    :destroyOnClose="false"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form-model :model="formData" :rules="validatorRules" :labelCol="labelCol" :wrapperCol="wrapperCol" ref="form">
        <!-- <a-form-model-model :labelCol="labelCol" :wrapperCol="wrapperCol"> -->
          <a-form-model-item prop="title" label="文章标题">
            <a-input placeholder="请输入标题" v-model="formData.title" />
          </a-form-model-item>
          <a-form-model-item label="文章描述">
            <a-input placeholder="请输入描述" v-model="formData.descr" />
          </a-form-model-item>
          <a-form-model-item prop="type" label="文章类型">
            <a-select placeholder="文章类型" v-model="formData.type">
              <a-select-option v-for="item in newTypeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="关键字">
            <span v-if="formData.keywords && formData.keywords.length">
              <a-tag
                :closable="true"
                @close="hanldleDeleteKeywords(text)"
                v-for="text in formData.keywords"
                :key="text"
                color="#87d068"
              >
                {{ text }}
              </a-tag>
            </span>
            <a-input v-model="keywordText" size="small" style="width: 70px; margin-right: 15px;" @keydown.enter="handleEditKeywords" @blur="handleEditKeywordsBlur" v-if="editKeyswords"></a-input>
            <a-button @click="editKeyswords = true" size="small"><a-icon type="plus-circle" /></a-button>
          </a-form-model-item>
          <a-form-model-item prop="releaseDate" label="发布时间">
            <a-date-picker
              format="YYYY-MM-DD HH:mm:ss"
              v-model="formData.releaseDate"
              style="width: 200px"
            />
          </a-form-model-item>
          <a-form-model-item prop="titleImage" label="标题图片">
            最多可上传1张照片（{{ fileList.length }}/1）
            <a-upload
              list-type="picture-card"
              :remove="file => handleRemove(file)"
              :multiple="false"
              accept=".jpg, .png, .jpeg"
              :fileList="fileList"
              @preview="handlePreview"
              :beforeUpload="file => beforeUpload(file)"
              :customRequest="customRequest"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-dropdown :trigger="['click']">
              <a-button :disabled="!formData.content" @click="handleGetImgFromArticle" type="primary">从文章中选择</a-button>
              <a-menu style="width: 100px; max-height: 300px; overflow: auto;" slot="overlay">
                <a-menu-item v-for="imgUrl in articleImgList" :key="imgUrl">
                  <a-card>
                    <img
                      @click="handleSelectImgFromArticle(imgUrl)"
                      style="max-width: 100%;"
                      slot="cover"
                      :src="imgUrl + '?x-oss-process=image/resize,w_100,m_lfit'"
                    />
                  </a-card>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-modal title="预览图片" :visible="previewVisible" :footer="null" @cancel="handleCancelPreview">
              <img alt="预览图片" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item prop="content" label="文章内容">
            <TinymceEditor v-model="formData.content" />
          </a-form-model-item>
        <!-- </a-form-model-model> -->
      </a-form-model>
    </a-spin>
    <div
      class="drawer-button"
    >
      <a-button style="margin-right: 8px;" @click="handleCancel">
        取消
      </a-button>
      <a-button style="margin-right: 8px;" type="primary" @click="handleDraft">
        暂存
      </a-button>
      <a-button type="primary" @click="handleRelease">
        发布
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import pick from 'lodash.pick'
import editFormModelMixin from '@/mixins/editFormModelMixin'
import { 
  aliyunOssUrl,
  aliyunUploadUrl,
  getNewsDetail,
  saveNews
} from '@/api'
import {
  filterObj,
  getImgSourcesFromStr,
  getFilename
} from '@/utils/util'
import moment from 'moment'
import TinymceEditor from '@/components/TinymceEditor'
const $imgGetter = getImgSourcesFromStr()
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  mixins: [editFormModelMixin],
  components: {
    TinymceEditor
  },
  data () {
    return {
      formData: {
        id: '',
        title: '',
        descr: '',
        type: undefined,
        titleImage: undefined,
        content: '',
        keywords: [],
        releaseDate: moment()
      },
      keywordText: '',
      state: '',
      editKeyswords: false,
      // 新闻
      fileList: [],
      // 图片
      // 新闻类型列表
      newTypeList: [],
      // 预览弹窗
      previewVisible: false,
      // 预览图片
      previewImage: '',
      // 上传oss图片
      ossImage: '',
      articleImgList: []
    }
  },
  computed: {
    validatorRules () {
      return {
        title: [
          {
            required: true,
            message: '请输入标题'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择新闻类型'
          }
        ],
        titleImage: [
          {
            required: true,
            message: '请上传标题图片'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入新闻内容'
          }
        ],
        releaseDate: [
          {
            required: true,
            message: '请输入发布日期'
          }
        ]
      }
    }
  },
  created () {
    this.first = true
  },
  methods: {
    handleSelectImgFromArticle (url) { // 从文文章中选择图片
      this.fileList = []
      const imageObj = {
        uid: 1,
        name: getFilename(url),
        status: 'done',
        url: url
      }
      this.formData.titleImage = url
      this.fileList.push(imageObj)
    },
    handleGetImgFromArticle () {
      if (this.formData.content === this.lastContent) return
      this.articleImgList = $imgGetter(this.formData.content)
      this.lastContent = this.formData.content
    },
    hanldleDeleteKeywords (v) { // 删除某个关键字
      this.formData.keywords.splice(this.formData.keywords.indexOf(v), 1)
    },
    handleEditKeywords (e) {
      if (e.target.value) {
        if (this.formData.keywords.indexOf(e.target.value) === -1) {
          this.formData.keywords.push(e.target.value)
          this.keywordText = ''
        } else {
          this.$message.error('关键字不能重复')
        }
      }
    },
    handleEditKeywordsBlur (e) { // 编辑关键字
      if (e.target.value) {
        if (this.formData.keywords.indexOf(e.target.value) === -1) {
          this.editKeyswords = false
          this.formData.keywords.push(e.target.value)
        }
      }
    },
    afterResetForm () {
      this.fileList = []
      this.previewImage = ''
      this.ossImage = ''
    },
    beforeAdd () {
      return {
        releaseDate: moment()
      }
    },
    beforeEdit (record) {
      return getNewsDetail({
        articleId: record.id
      })
        .then(data => {
          if (data.data.titleImage) {
            this.fileList = []
            const imageObj = {
              uid: 1,
              name: 'newName',
              status: 'done',
              url: data.data.titleImage
            }
            this.fileList.push(imageObj)
            this.formData.titleImage = data.data.titleImage
          }
          const content = this.escape2Html(data.data.content)
          this.formData.content = content
          let releaseDate
          if (data.data.releaseDate) {
            releaseDate = moment(data.data.releaseDate)
          }
          return {
            ...data.data,
            releaseDate: releaseDate || moment(),
            keywords: (data.data.keywords && data.data.keywords.split(',')) || []
          }
        })
    },
    // 转义html
    escape2Html (str) {
      str = str.replace(/src="\/Upload/ig, 'src="http://old.aihmbj.com/Upload');
      // str = str.replace(/src=&quot;\//ig, "src=&quot;http://old.aihmbj.com/");
      let arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t]; });
    },
    // 取消预览
    handleCancelPreview() {
      this.previewVisible = false
    },
    // 删除图片
    handleRemove(f) {
      const index = this.fileList.indexOf(f)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    // 预览图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 上传之前
    beforeUpload(file) {
      if (this.fileList.length > 1) {
        this.$message.warning('最多可上传1张照片!')
        return false
      }
      if (file.size > 3 * 1024 * 1024) {
        this.$message.warning('请上传小于3M的图片!')
        return false
      }
    },
    // 上传文件改变时的状态
    handleChange(info) {
      const imageNum = this.fileList.length
      let fileList = [...info.fileList]
      fileList = fileList.slice(0, imageNum)
    },
    // 自定义上传
    customRequest(fileObj) {
      // 上传文件
      this.uploadFile(fileObj.file)
    },
    // 上传文件
    uploadFile(file) {
      const extArr = file.name.split('.')
      const ext = extArr[extArr.length - 1]
      // 新文件名
      const newName = file.uid + '.' + ext
      const formData = {
        file: file
      }
      const params = new FormData()
      Object.keys(formData).map((key) => {
        params.append(key, formData[key])
      })
      aliyunUploadUrl(params).then(data => {
        const uid = this.fileList.length
        const imageObj = {
          uid: uid,
          name: newName,
          status: 'done',
          url: data.data
        }
        this.fileList.push(imageObj)
        this.formData.titleImage = data.data
        this.$refs.form.clearValidate('titleImage')
      })
    },
    handleDraft () {
      this.state = 'draft'
      this.handleOk()
    },
    handleRelease () {
      this.state = 'released'
      this.handleOk()
    },
    handleSave () { // 新增或修改
      return saveNews(filterObj({
        ...this.formData,
        articleId: this.formData.id || 'add',
        keywords: this.formData.keywords.toString(),
        releaseDate: this.formData.releaseDate.format('YYYY-MM-DD HH:mm:ss'),
        state: this.state
      }))
    }
  }
}
</script>

